<template>
  <ModalSide @close="close">
    <template v-slot:tabs>
      <button
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>{{
          $t("components.brandMarketing.assets.assetsModalUpload.upload")
        }}</span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>

    <template v-slot:content>
      <ModalSideContent class="flex-grow overflow-y-scroll">
        <UploadMultiple
          class="border-b -mx-5 -mt-5 mb-8 border-neutral-50 bg-neutral-0 p-5 md:-mx-8 md:-mt-8 md:p-8"
          :file-types="
            allowedFileTypes.length &&
            allowedFileTypes.map((fileType) => fileType.type)
          "
          @filesChanged="updateFiles"
        />

        <AssetsForm
          :categories="categories"
          :tags="tags"
          :multiple-files="selectedFiles.length > 1"
          :asset-packages="assetPackages"
          @updateName="updateName"
          @updateCategoryUuid="updateCategoryUuid"
          @updateTags="updateTags"
          @updateExpireDate="updateExpireDate"
          @updateAssetPackageUuid="updateAssetPackageUuid"
        />
        <div
          v-if="formError"
          class="rounded-sm border-l-2 border-danger-100 bg-danger-50 px-3 py-2 text-xs font-medium text-danger-500"
        >
          {{ formError }}
        </div>
      </ModalSideContent>

      <ModalSideActions class="justify-end">
        <div v-if="progress > 0" class="mr-auto">
          <div
            class="relative h-1 w-24 overflow-hidden rounded-full bg-neutral-100"
          >
            <span
              class="duration-250 absolute top-0 left-0 bottom-0 bg-primary-500 transition-all"
              :style="{ width: progress + '%' }"
            ></span>
          </div>
        </div>
        <BaseButton
          :text="$t(`global.cancel`)"
          size="medium"
          variant="outline"
          @buttonClick="close"
        ></BaseButton>

        <BaseButton
          :text="$t(`global.saveChanges`)"
          size="medium"
          variant="fill"
          class="ml-2"
          :disabled="!inputValid || status === 'uploading'"
          @buttonClick="submitHandler"
        >
        </BaseButton
      ></ModalSideActions>
    </template>
  </ModalSide>
</template>

<script>
import axios from "axios";

import AssetsForm from "@/components/brand-marketing/assets/AssetsForm";
import ModalSide from "@/components/common/ModalSide";
import ModalSideContent from "@/components/common/ModalSideContent";
import ModalSideActions from "@/components/common/ModalSideActions";
import UploadMultiple from "@/components/common/UploadMultiple";

import CREATE_ASSET from "@/graphql/CreateAsset.gql";
import FIND_ASSETS from "@/graphql/FindAssets.gql";
import FIND_ALLOWED_FILE_TYPES from "@/graphql/FindAllowedAssetFileTypes.gql";
import FIND_ASSET_PACKAGES from "@/graphql/FindAssetPackages.gql";
import ADD_ASSETS_TO_ASSET_PACKAGE from "@/graphql/AddAssetsToAssetPackage.gql";

export default {
  components: {
    AssetsForm,
    ModalSide,
    ModalSideContent,
    ModalSideActions,
    UploadMultiple,
  },

  apollo: {
    fileTypes: {
      query: FIND_ALLOWED_FILE_TYPES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    assetPackages: {
      query: FIND_ASSET_PACKAGES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
  },

  props: {
    categories: {
      type: Array,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    allowedFileTypes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      status: "idle",
      formError: null,
      progress: 0,
      uploadError: null,
      assetName: null,
      assetCategoryUuid: null,
      assetFileTypeUuid: null,
      assetPackageUuid: null,
      assetTagsUuids: [],
      assetExpireDate: null,
      selectedFiles: [],
      selectedAssets: [],
      assetPackages: [],
      fileTypes: [],
    };
  },

  computed: {
    inputValid() {
      return (
        !!this.assetCategoryUuid &&
        this.selectedAssets.length &&
        this.selectedFiles.length
      );
    },
  },

  methods: {
    cleanFilename(filename) {
      return filename.replace(/\.[^/.]+$/, "");
    },

    updateName(assetName) {
      this.assetName = assetName;
      this.updateAssets();
    },
    updateCategoryUuid(assetCategoryUuid) {
      this.assetCategoryUuid = assetCategoryUuid;
      this.updateAssets();
    },
    updateTags(tagsUuids) {
      this.assetTagsUuids = tagsUuids;
      this.updateAssets();
    },
    updateExpireDate(assetExpireDate) {
      this.assetExpireDate = assetExpireDate;
      this.updateAssets();
    },
    updateAssetPackageUuid(assetPackageUuid) {
      this.assetPackageUuid = assetPackageUuid;
      this.updateAssets();
    },
    updateFiles(files) {
      this.selectedFiles = files;
      this.updateAssets();
    },

    determineFileTypeUuid(file) {
      const fileType = this.allowedFileTypes.find(
        (fileType) => fileType.type == file.type,
      );

      if (!fileType) {
        return;
      }

      return fileType.uuid;
    },

    async uploadFile(file) {
      const assetFileTypeUuid = this.determineFileTypeUuid(file);

      if (!assetFileTypeUuid) {
        this.status = "error";
        this.uploadError = this.$i18n.t(
          "components.brandMarketing.assets.assetsModalUpload.filetypeNotAllowed",
        );
        return;
      }

      const bodyFormData = new FormData();
      bodyFormData.append("asset", file);
      bodyFormData.append("assetFileTypeUuid", assetFileTypeUuid);

      try {
        const response = await axios({
          method: "post",
          url: `${this.$env.value("VUE_APP_API_HTTP")}/assets/upload`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });

        return response.data.fileUuid;
      } catch (error) {
        this.status = "error";
        this.uploadError = error.response.data.error.message;
      }
    },

    updateAssets() {
      this.selectedAssets = this.selectedFiles.map((file) => {
        return {
          name:
            (this.selectedFiles.length === 1 && this.assetName) ||
            this.cleanFilename(file.name),
          categoryUuid: this.assetCategoryUuid,
          tags: this.assetTagsUuids,
          expireDate: this.assetExpireDate,
        };
      });
    },

    fakeProgressTimer(fileSize) {
      const bytesPerSecond = 5e6; // 10 mb/sec
      const timeout = 100; // 100 ms

      let currentProgress = 0;
      let bytesTransferred = 0;

      let timer = setInterval(() => {
        bytesTransferred += bytesPerSecond / 10;
        currentProgress = Math.min((bytesTransferred / fileSize) * 100, 90);

        this.progress = currentProgress;

        if (bytesTransferred >= fileSize) {
          clearInterval(timer);
        }
      }, timeout);
    },

    close() {
      this.$emit("close");
    },

    async submitOneAsset({ name, fileUuid }) {
      let uuid;

      await this.$apollo
        .mutate({
          mutation: CREATE_ASSET,
          variables: {
            name: name || this.assetName,
            categoryUuid: this.assetCategoryUuid,
            fileUuid: fileUuid || this.fileUuid,
            tags: this.assetTagsUuids,
            expireDate: this.assetExpireDate,
          },
          update: (store, { data: { createdAsset } }) => {
            const data = store.readQuery({
              query: FIND_ASSETS,
              variables: {
                categories: [],
                tags: [],
                fileTypes: [],
              },
            });

            data.assets.unshift(createdAsset);
            store.writeQuery({ query: FIND_ASSETS, data });
          },
        })
        .then(({ data: { createdAsset } }) => {
          uuid = createdAsset.uuid;
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message);
        });

      return uuid;
    },

    addAssetsToAssetPackage() {
      if (!this.assetPackageUuid) {
        this.$emit("resetFilters");
        this.close();
      }

      this.$apollo
        .mutate({
          mutation: ADD_ASSETS_TO_ASSET_PACKAGE,
          variables: {
            uuid: this.assetPackageUuid,
            assetsToAdd: this.selectedAssets.map(
              (selectedAsset) => selectedAsset.uuid,
            ),
          },
        })
        .then(() => {
          this.$emit("resetFilters");
          this.close();
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message);
        });
    },

    async submitHandler() {
      const totalFileSize = this.selectedFiles.reduce(
        (total, file) => total + file.size,
        0,
      );

      this.status = "uploading";

      this.fakeProgressTimer(totalFileSize);

      await Promise.all(
        this.selectedFiles.map(async (file) => {
          const uuid = await this.uploadFile(file);
          if (!uuid) return;

          file.uuid = uuid;

          const asset = this.selectedAssets.find(
            (asset) =>
              asset.name ===
              ((this.selectedFiles.length === 1 && this.assetName) ||
                this.cleanFilename(file.name)),
          );

          if (asset) {
            asset.fileUuid = file.uuid;
            asset.uuid = await this.submitOneAsset(asset);
          }
        }),
      );

      this.selectedAssets.every((asset) => asset.uuid)
        ? this.addAssetsToAssetPackage()
        : this.close();

      this.status = "finished";
    },
  },
};
</script>
