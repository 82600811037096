var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rounded-t animation-default fixed bottom-0 right-0 z-20 mr-8 hidden w-full max-w-sm bg-white shadow-2xl animation-modalSlide animation-bezier animation-once lg:block"},[(_vm.currentStep === 'selectAssets')?_c('PackageBuilderHeader',{on:{"closePackageBuilder":_vm.closePackageBuilder},nativeOn:{"click":function($event){return _vm.toggleMinify.apply(null, arguments)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$tc( "components.brandMarketing.packageBuilder.packageBuilder.numberSelected", _vm.selectedAssets.length, { amount: _vm.selectedAssets.length, } ))+" ")]},proxy:true},{key:"controls",fn:function(){return [_c('div',{staticClass:"relative h-6 w-6"},[_c('IconChevronDown',{class:[
            'absolute inset-0 transform transition-transform duration-300',
            { 'rotate-180': _vm.minified } ]})],1)]},proxy:true}],null,false,3631092419)}):_vm._e(),(_vm.currentStep === 'successfullyAddedAssets')?_c('PackageBuilderHeader',{on:{"closePackageBuilder":_vm.closePackageBuilder},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t( "components.brandMarketing.packageBuilder.packageBuilder.successfullyAdded" ))+" ")]},proxy:true}],null,false,1445289159)}):_vm._e(),_c('div',{class:[
      'overflow-y-scroll transition-all duration-300',
      { 'h-0': _vm.minified },
      { 'h-88': !_vm.minified } ]},[(_vm.currentStep === 'selectAssets')?_c('div',{staticClass:"p-4"},_vm._l((_vm.selectedAssets),function(selectedAsset,index){return _c('PackageBuilderAssetRow',{key:index,attrs:{"asset":selectedAsset}})}),1):_vm._e(),(_vm.currentStep === 'successfullyAddedAssets')?_c('div',{staticClass:"flex h-full items-center justify-center p-4"},[_c('PackageBuilderSuccess',{attrs:{"uuid":_vm.selectedAssetPackage.uuid,"amount":_vm.savedAssetsAmount,"name":_vm.selectedAssetPackage.name}})],1):_vm._e()]),_c('div',{class:[
      'border-t p-4',
      { 'border-neutral-100': _vm.currentStep === 'selectAssets' },
      { 'border-transparent': _vm.currentStep === 'successfullyAddedAssets' } ]},[(_vm.currentStep === 'selectAssets')?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-grow relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedAssetPackageUuid),expression:"selectedAssetPackageUuid"}],staticClass:"border focus:outline-none h-10 w-full cursor-pointer appearance-none rounded-l-sm rounded-r-none border-neutral-100 bg-neutral-0 px-4 pr-8 text-sm shadow-sm focus:border-neutral-300",attrs:{"required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedAssetPackageUuid=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"-1","selected":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t( "components.brandMarketing.packageBuilder.packageBuilder.selectPlaceholder" ))+" ")]),_vm._l((_vm.assetPackages),function(assetPackage,index){return _c('option',{key:index,domProps:{"value":assetPackage.uuid}},[_vm._v(" "+_vm._s(assetPackage.name)+" ")])})],2),_c('div',{staticClass:"pointer-events-none absolute right-0 top-0 bottom-0 flex items-center pr-2"},[_c('IconSortArrow')],1)]),_c('button',{class:[
          'rounded-r focus:outline-none h-10 px-6 text-sm font-medium text-white transition-all duration-300',
          { 'bg-neutral-100': !_vm.inputValid },
          {
            'bg-primary-600 shadow-inner hover:bg-primary-700': _vm.inputValid,
          } ],attrs:{"type":"submit","disabled":!_vm.inputValid},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("components.brandMarketing.packageBuilder.packageBuilder.add"))+" ")])]):_vm._e(),(_vm.formError)?_c('div',{staticClass:"rounded-sm border-l-2 border-danger-100 bg-danger-50 px-3 py-2 text-xs font-medium text-danger-500"},[_vm._v(" "+_vm._s(_vm.formError)+" ")]):_vm._e(),(_vm.currentStep === 'successfullyAddedAssets')?_c('div',{staticClass:"flex"},[_c('button',{staticClass:"border focus:outline-none inline-flex h-10 w-full cursor-pointer justify-center rounded-sm border-neutral-100 py-2 px-5 pl-3 text-sm font-medium transition-all duration-300 hover:border-neutral-200 focus:shadow-focus",on:{"click":_vm.goToPackage}},[_vm._v(" "+_vm._s(_vm.$t( "components.brandMarketing.packageBuilder.packageBuilder.goToPackage" ))+" ")])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }