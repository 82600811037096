<template>
  <div @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <input
      id="fileInput"
      ref="file"
      type="file"
      multiple
      name="file"
      class="hidden"
      :accept="
        fileTypes.map((fileType) => fileType.replace(/,*$/, '')).join(',')
      "
      @change="onChange"
    />

    <label
      for="fileInput"
      class="border flex h-32 cursor-pointer items-center justify-center rounded-sm border-dashed border-neutral-200 text-sm text-neutral-700"
    >
      <div v-if="isDragging">Release to drop files here</div>
      <div v-else>Drop files or <u>click here</u> to upload</div>
    </label>

    <div v-if="files.length" class="mt-4">
      <div
        v-for="file in files"
        :key="file.name"
        class="mt-2 flex items-center"
      >
        <figure
          class="border relative mr-3 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-sm border-neutral-50 bg-neutral-0 text-neutral-300"
        >
          <img
            :src="generateURL(file)"
            class="absolute top-0 left-0 h-full w-full rounded-sm object-cover"
          />
        </figure>

        <div class="flex w-full min-w-0 items-center gap-4 text-xs">
          <div class="overflow-hidden">
            <div class="truncate">
              {{ file.name }}
            </div>
            <div class="text-neutral-300">
              {{ Math.round(file.size / 1000) + "kb" }}
            </div>
          </div>
          <div class="ml-auto">
            <button
              class="text-neutral-300 hover:text-neutral-500"
              type="button"
              title="Remove file"
              @click="remove(files.indexOf(file))"
            >
              <IconTrash class="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fileTypes: {
      type: Array,
      required: false,
      default: () => [".pdf", ".jpg", ".jpeg", ".png"],
    },
  },
  data() {
    return {
      isDragging: false,
      files: [],
    };
  },
  methods: {
    onChange() {
      this.files = [...this.$refs.file.files];
      this.$emit("filesChanged", this.files);
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove(i) {
      this.files.splice(i, 1);
    },
    generateURL(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
  },
};
</script>
