<template>
  <div
    class="rounded-t animation-default fixed bottom-0 right-0 z-20 mr-8 hidden w-full max-w-sm bg-white shadow-2xl animation-modalSlide animation-bezier animation-once lg:block"
  >
    <PackageBuilderHeader
      v-if="currentStep === 'selectAssets'"
      @closePackageBuilder="closePackageBuilder"
      @click.native="toggleMinify"
    >
      <template v-slot:title>
        {{
          $tc(
            "components.brandMarketing.packageBuilder.packageBuilder.numberSelected",
            selectedAssets.length,
            {
              amount: selectedAssets.length,
            },
          )
        }}
      </template>
      <template v-slot:controls>
        <div class="relative h-6 w-6">
          <IconChevronDown
            :class="[
              'absolute inset-0 transform transition-transform duration-300',
              { 'rotate-180': minified },
            ]"
          />
        </div>
      </template>
    </PackageBuilderHeader>
    <PackageBuilderHeader
      v-if="currentStep === 'successfullyAddedAssets'"
      @closePackageBuilder="closePackageBuilder"
    >
      <template v-slot:title>
        {{
          $t(
            "components.brandMarketing.packageBuilder.packageBuilder.successfullyAdded",
          )
        }}
      </template>
    </PackageBuilderHeader>
    <div
      :class="[
        'overflow-y-scroll transition-all duration-300',
        { 'h-0': minified },
        { 'h-88': !minified },
      ]"
    >
      <div v-if="currentStep === 'selectAssets'" class="p-4">
        <PackageBuilderAssetRow
          v-for="(selectedAsset, index) in selectedAssets"
          :key="index"
          :asset="selectedAsset"
        />
      </div>
      <div
        v-if="currentStep === 'successfullyAddedAssets'"
        class="flex h-full items-center justify-center p-4"
      >
        <PackageBuilderSuccess
          :uuid="selectedAssetPackage.uuid"
          :amount="savedAssetsAmount"
          :name="selectedAssetPackage.name"
        />
      </div>
    </div>
    <div
      :class="[
        'border-t p-4',
        { 'border-neutral-100': currentStep === 'selectAssets' },
        { 'border-transparent': currentStep === 'successfullyAddedAssets' },
      ]"
    >
      <div v-if="currentStep === 'selectAssets'" class="flex">
        <div class="flex-grow relative">
          <select
            v-model="selectedAssetPackageUuid"
            class="border focus:outline-none h-10 w-full cursor-pointer appearance-none rounded-l-sm rounded-r-none border-neutral-100 bg-neutral-0 px-4 pr-8 text-sm shadow-sm focus:border-neutral-300"
            required
          >
            <option value="-1" selected disabled>
              {{
                $t(
                  "components.brandMarketing.packageBuilder.packageBuilder.selectPlaceholder",
                )
              }}
            </option>
            <option
              v-for="(assetPackage, index) in assetPackages"
              :key="index"
              :value="assetPackage.uuid"
            >
              {{ assetPackage.name }}
            </option>
          </select>
          <div
            class="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center pr-2"
          >
            <IconSortArrow />
          </div>
        </div>
        <button
          type="submit"
          :disabled="!inputValid"
          :class="[
            'rounded-r focus:outline-none h-10 px-6 text-sm font-medium text-white transition-all duration-300',
            { 'bg-neutral-100': !inputValid },
            {
              'bg-primary-600 shadow-inner hover:bg-primary-700': inputValid,
            },
          ]"
          @click="submit"
        >
          {{
            $t("components.brandMarketing.packageBuilder.packageBuilder.add")
          }}
        </button>
      </div>
      <div
        v-if="formError"
        class="rounded-sm border-l-2 border-danger-100 bg-danger-50 px-3 py-2 text-xs font-medium text-danger-500"
      >
        {{ formError }}
      </div>
      <div v-if="currentStep === 'successfullyAddedAssets'" class="flex">
        <button
          class="border focus:outline-none inline-flex h-10 w-full cursor-pointer justify-center rounded-sm border-neutral-100 py-2 px-5 pl-3 text-sm font-medium transition-all duration-300 hover:border-neutral-200 focus:shadow-focus"
          @click="goToPackage"
        >
          {{
            $t(
              "components.brandMarketing.packageBuilder.packageBuilder.goToPackage",
            )
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PackageBuilderAssetRow from "@/components/brand-marketing/package-builder/PackageBuilderAssetRow";
import PackageBuilderHeader from "@/components/brand-marketing/package-builder/PackageBuilderHeader";
import PackageBuilderSuccess from "@/components/brand-marketing/package-builder/PackageBuilderSuccess";

import { mapActions, mapGetters } from "vuex";

import ADD_ASSETS_TO_ASSET_PACKAGE from "@/graphql/AddAssetsToAssetPackage.gql";

export default {
  components: {
    PackageBuilderAssetRow,
    PackageBuilderHeader,
    PackageBuilderSuccess,
  },
  props: {
    assetPackages: {
      type: Array,
      required: true,
    },
    selectedAssets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      savedAssetsAmount: 0,
      selectedAssetPackageUuid: -1,
      minified: true,
      selectedAssetPackage: null,
      formError: null,
    };
  },
  computed: {
    ...mapGetters("packageBuilder", ["currentStep"]),
    inputValid() {
      return this.selectedAssetPackageUuid !== -1;
    },
    selectedAssetsText() {
      if (this.selectedAssets.length === 1) {
        return "asset";
      }
      return "assets";
    },
  },
  watch: {
    selectedAssetPackageUuid: function (selectedUuid) {
      this.selectedAssetPackage = this.assetPackages.find(
        (assetPackage) => assetPackage.uuid === selectedUuid,
      );
    },
  },
  methods: {
    ...mapActions("packageBuilder", ["setCurrentStep"]),

    toggleMinify() {
      if (this.currentStep === "selectAssets") {
        this.minified = !this.minified;
      }
    },
    closePackageBuilder() {
      this.$emit("close");
    },
    goToPackage() {
      this.$router.push({
        name: "brand-marketing-packages-detail",
        params: { packageUuid: this.selectedAssetPackageUuid },
      });
      this.$emit("close");
    },
    submit() {
      if (this.inputValid === true) {
        this.$apollo
          .mutate({
            mutation: ADD_ASSETS_TO_ASSET_PACKAGE,
            variables: {
              uuid: this.selectedAssetPackageUuid,
              assetsToAdd: this.selectedAssets.map(
                (selectedAsset) => selectedAsset.uuid,
              ),
            },
          })
          .then(() => {
            this.formError = null;
            this.$emit("finishedAddingToPackage");
            this.savedAssetsAmount = this.selectedAssets.length;
            this.setCurrentStep("successfullyAddedAssets");
            this.minified = false;
          })
          .catch((error) => {
            this.formError = JSON.stringify(error.message);
          });
      }
    },
  },
};
</script>

<style scoped>
@keyframes successAnimation {
  40%,
  60% {
    @apply bg-success-600;
  }
}
.success-animation {
  animation: 0.8s successAnimation 1;
}
</style>
