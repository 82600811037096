<template>
  <div
    :class="[
      'rounded-t flex items-center bg-neutral-900 p-4 transition-all duration-300',
      { 'cursor-pointer bg-neutral-900': currentStep === 'selectAssets' },
      { 'success-animation': currentStep === 'successfullyAddedAssets' },
    ]"
  >
    <div class="flex-grow text-sm font-medium text-white">
      <slot name="title"></slot>
    </div>
    <div class="flex flex-shrink-0 items-center text-white">
      <slot name="controls"></slot>
      <button class="focus:outline-none ml-2" @click="close">
        <IconClose />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("packageBuilder", ["currentStep"]),
    inputValid() {
      return this.selectedAssetPackageUuid !== -1;
    },
  },
  methods: {
    close() {
      this.$emit("closePackageBuilder");
    },
  },
};
</script>
