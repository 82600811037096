<template>
  <div class="overflow-y-scroll">
    <ModalSideContent class="grid gap-6">
      <TextField
        v-model="name"
        :label="translations.nameLabel"
        :placeholder="translations.namePlaceholder"
        :focus="name === null"
        :maxlength="maxLengthName"
        @input="updateName"
      />
      <TextArea
        v-model="description"
        :label="translations.descriptionLabel"
        :placeholder="translations.descriptionPlaceholder"
        :maxlength="maxLengthDescription"
        :counter="showCounter"
        @input="updateDescription"
      />

      <SelectField
        v-model="categoryUuid"
        :label="translations.categoryLabel"
        @input="updateCategoryUuid"
      >
        <option value="-1" selected disabled>
          {{ translations.categoryPlaceholder }}
        </option>
        <option
          v-for="category in categories"
          :key="category.uuid"
          :value="category.uuid"
          :selected="categoryUuid === category.uuid"
        >
          {{ category.name }}
        </option>
      </SelectField>
    </ModalSideContent>
    <ModalSideContent class="grid gap-6">
      <TagSelector
        :label="translations.rolesLabel"
        :placeholder="translations.rolesPlaceholder"
        :options="mappedRoles"
        :initial-value="selectedRoles"
        @updateTags="updateSelectedRoles"
      />
      <DateInput
        v-model="dueDate"
        :label="translations.downloadBeforeLabel"
        :placeholder="translations.downloadBeforePlaceholder"
        :disable-dates-until="disableDatesUntil"
        @input="updateDueDate"
      />
    </ModalSideContent>
    <ModalSideContent class="grid gap-6">
      <Checkbox
        v-model="isFeatured"
        :label="translations.featuredPackageLabel"
        @input="updateIsFeatured"
      />
      <div v-if="isFeatured" class="grid gap-6">
        <CoverImageUpload
          v-model="coverImageFileUuid"
          :current-image="coverImage"
          :is-uploading-cover-image="isUploadingCoverImage"
          :cover-image-upload-error="coverImageUploadError"
          :upload-cover-image="uploadCoverImage"
          :remove-cover-image="removeCoverImage"
          class="h-64"
        />
        <TextField
          v-model="sortOrder"
          :label="translations.sortLabel"
          :placeholder="translations.sortPlaceholder"
          :type="'number'"
          @input="updateSortOrder"
        />
      </div>
    </ModalSideContent>
  </div>
</template>

<script>
import TagSelector from "@/components/common/TagSelector";
import DateInput from "@/components/common/DateInput";
import ModalSideContent from "@/components/common/ModalSideContent";
import CoverImageUpload from "@/components/menus/CoverImageUpload";

import axios from "axios";
import env from "@/env";

export default {
  components: {
    TagSelector,
    DateInput,
    ModalSideContent,
    CoverImageUpload,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    currentAssetPackage: {
      type: Object,
      default: () => {
        return {
          name: null,
          category: {
            uuid: "-1",
          },
          description: "",
          roles: [],
          dueDate: null,
          isFeatured: false,
          coverImage: {
            uuid: null,
          },
        };
      },
    },
  },

  data() {
    return {
      name: this.currentAssetPackage.name,
      description: this.currentAssetPackage.description || "",
      categoryUuid: this.currentAssetPackage.category.uuid,
      selectedRoles: this.currentAssetPackage.roles.map((role) => ({
        uuid: role.id,
        name: role.description,
      })),
      mappedRoles: this.roles.map((role) => ({
        uuid: role.id,
        name: role.description,
      })),
      dueDate: this.currentAssetPackage.dueDate
        ? new Date(this.currentAssetPackage.dueDate)
        : null,
      isFeatured: this.currentAssetPackage.isFeatured,
      maxLengthName: 40,
      maxLengthDescription: 184,
      showCounter: true,
      disableDatesUntil: new Date(),

      isUploadingCoverImage: false,
      coverImageUploadError: null,
      coverImage: this.currentAssetPackage.coverImage,
      coverImageFileUuid: this.currentAssetPackage.coverImage
        ? this.currentAssetPackage.coverImage.uuid
        : this.currentAssetPackage.coverImage,
      sortOrder: this.currentAssetPackage.sortOrder || "0",
      file: null,

      translations: {
        nameLabel: this.$t(
          "components.brandMarketing.packages.packageForm.name",
        ),
        namePlaceholder: this.$t(
          "components.brandMarketing.packages.packageForm.namePlaceholder",
        ),
        descriptionLabel: this.$t(
          "components.brandMarketing.packages.packageForm.description",
        ),
        descriptionPlaceholder: this.$t(
          "components.brandMarketing.packages.packageForm.descriptionPlaceholder",
        ),
        categoryLabel: this.$t(
          "components.brandMarketing.packages.packageForm.category",
        ),
        categoryPlaceholder: this.$t(
          "components.brandMarketing.packages.packageForm.categoryPlaceholder",
        ),
        rolesLabel: this.$t(
          "components.brandMarketing.packages.packageForm.roles",
        ),
        rolesPlaceholder: this.$t(
          "components.brandMarketing.packages.packageForm.rolesPlaceholder",
        ),
        downloadBeforeLabel: this.$t(
          "components.brandMarketing.packages.packageForm.downloadBefore",
        ),
        downloadBeforePlaceholder: this.$t(
          "components.brandMarketing.packages.packageForm.downloadBeforePlaceholder",
        ),
        featuredPackageLabel: this.$t(
          "components.brandMarketing.packages.packageForm.featuredPackage",
        ),
        sortLabel: this.$t(
          "components.brandMarketing.packages.packageForm.sort",
        ),
        sortPlaceholder: this.$t(
          "components.brandMarketing.packages.packageForm.sortPlaceholder",
        ),
      },
    };
  },

  watch: {
    currentAssetPackage: function (newValue) {
      this.name = newValue.name;
      this.description = newValue.description || "";
      this.categoryUuid = newValue.category.uuid;
      this.dueDate = new Date(newValue.dueDate);
      this.selectedRoles = newValue.roles;
      this.isFeatured = newValue.isFeatured;
      this.coverImageFileUuid = newValue.coverImage
        ? newValue.coverImage.uuid
        : null;
      this.sortOrder = newValue.sortOrder;
    },
  },

  mounted() {
    this.updateSortOrder();
  },

  methods: {
    updateName() {
      this.$emit("updateName", this.name);
    },
    updateDescription() {
      this.$emit("updateDescription", this.description);
    },
    updateCategoryUuid() {
      this.$emit("updateCategoryUuid", this.categoryUuid);
    },
    updateSelectedRoles(roleIds) {
      this.$emit("updateSelectedRoles", roleIds);
    },
    updateDueDate() {
      this.$emit("updateDueDate", this.dueDate);
    },
    updateIsFeatured() {
      this.$emit("updateIsFeatured", this.isFeatured);
    },
    updateCoverImageFileUuid() {
      this.$emit("updateCoverImageFileUuid", this.coverImageFileUuid);
    },
    async uploadCoverImage(file) {
      this.isUploadingCoverImage = true;
      this.coverImageUploadError = null;

      const bodyFormData = new FormData();
      bodyFormData.append("file", file);

      try {
        const response = await axios({
          method: "post",
          url: `${env.value("VUE_APP_API_HTTP")}/media/upload/image`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        this.coverImageFileUuid = response.data.fileUuid;
        this.isUploadingCoverImage = false;
        this.updateCoverImageFileUuid();
      } catch (error) {
        this.coverImageUploadError = error.message;
        this.isUploadingCoverImage = false;
      }
    },
    removeCoverImage() {
      this.coverImageFileUuid = null;
      this.updateCoverImageFileUuid();
    },
    updateSortOrder() {
      this.$emit("updateSortOrder", parseInt(this.sortOrder));
    },
  },
};
</script>
