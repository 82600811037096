<template>
  <div>
    <Toolbar>
      <template v-slot:title>
        {{ $t("pages.brandMarketing.title") }}
      </template>
      <template v-slot:subNavigation>
        <ToolbarNavigation />
      </template>
      <template v-slot:actions>
        <ToolbarActions
          @clickedUploadButton="openModalUpload"
          @clickedCreatePackageButton="openModalCreatePackage"
        />
      </template>
    </Toolbar>

    <section class="min-h-screen-toolbar py-6 md:py-10">
      <router-view></router-view>
    </section>

    <transition name="fade">
      <AssetsModalUpload
        v-if="modalUploadOpen"
        :categories="categories"
        :tags="tags"
        :allowed-file-types="fileTypes"
        @close="closeModal"
        @resetFilters="resetFilters"
      />

      <PackageModalCreate
        v-if="modalPackageCreateOpen"
        :categories="categories"
        :roles="roles"
        @close="closeModal"
        @resetFilters="resetFilters"
      />

      <PackageBuilder
        v-if="showPackageBuilder"
        :asset-packages="assetPackages"
        :selected-assets="selectedAssets"
        @close="hidePackageBuilder"
        @finishedAddingToPackage="clearSelectedAssets"
      />
    </transition>
  </div>
</template>

<script>
import ToolbarActions from "@/components/brand-marketing/ToolbarActions";
import ToolbarNavigation from "@/components/brand-marketing/ToolbarNavigation";
import AssetsModalUpload from "@/components/brand-marketing/assets/AssetsModalUpload";
import PackageModalCreate from "@/components/brand-marketing/packages/PackageModalCreate";
import PackageBuilder from "@/components/brand-marketing/package-builder/PackageBuilder";

import { mapActions, mapGetters } from "vuex";

import FIND_ASSETS_CATEGORIES from "@/graphql/FindAssetsCategories.gql";
import FIND_ASSETS_TAGS from "@/graphql/FindAssetsTags.gql";
import FIND_ALLOWED_FILE_TYPES from "@/graphql/FindAllowedAssetFileTypes.gql";
import FIND_ASSET_PACKAGES from "@/graphql/FindAssetPackages.gql";
import FIND_ROLES from "@/graphql/FindRoles.gql";

export default {
  name: "BrandMarketing",

  components: {
    ToolbarActions,
    ToolbarNavigation,
    AssetsModalUpload,
    PackageModalCreate,
    PackageBuilder,
  },

  props: {
    openPackageCreateModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modalUploadOpen: false,
      modalPackageCreateOpen: this.openPackageCreateModal,

      assetPackages: [],
      categories: [],
      tags: [],
      fileTypes: [],
      roles: [],
    };
  },

  computed: {
    ...mapGetters("packageBuilder", ["showPackageBuilder", "selectedAssets"]),
  },

  apollo: {
    categories: {
      query: FIND_ASSETS_CATEGORIES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    tags: {
      query: FIND_ASSETS_TAGS,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    fileTypes: {
      query: FIND_ALLOWED_FILE_TYPES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    assetPackages: {
      query: FIND_ASSET_PACKAGES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    roles: {
      query: FIND_ROLES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
      skip() {
        return !this.$can("read:roles");
      },
    },
  },

  methods: {
    ...mapActions("packageBuilder", [
      "hidePackageBuilder",
      "clearSelectedAssets",
    ]),

    openModalDetails(asset) {
      this.focusedAsset = asset;
      this.modalDetailsOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },

    openModalUpload() {
      this.modalUploadOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },

    openModalCreatePackage() {
      this.modalPackageCreateOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },

    closeModal() {
      this.modalUploadOpen = false;
      this.modalPackageCreateOpen = false;
      this.selectedFile = null;
      document.body.classList.remove("overflow-y-hidden");
    },

    resetFilters() {
      this.$emit("emitResetFilters");
    },
  },
};
</script>
