<template>
  <ModalSide @close="closeModal">
    <template v-slot:tabs>
      <button
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>{{
          $t(
            "components.brandMarketing.packages.packageModalCreate.createPackage",
          )
        }}</span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>

    <template v-slot:content class="flex-grow overflow-y-scroll">
      <PackageForm
        class="flex-grow"
        :categories="categories"
        :roles="roles"
        @updateName="updateName"
        @updateCategoryUuid="updateCategoryUuid"
        @updateDescription="updateDescription"
        @updateSelectedRoles="updateSelectedRoles"
        @updateDueDate="updateDueDate"
        @updateIsFeatured="updateIsFeatured"
        @updateCoverImageFileUuid="updateCoverImageFileUuid"
        @updateSortOrder="updateSortOrder"
      />

      <div class="px-8">
        <div
          v-if="formError"
          class="bg-danger-0 mb-6 rounded-sm border-l-2 border-danger-100 px-3 py-2 text-xs font-medium text-danger-500"
        >
          {{ formError }}
        </div>
      </div>

      <ModalSideActions class="justify-end">
        <BaseButton
          :text="$t(`global.cancel`)"
          size="medium"
          variant="outline"
          @buttonClick="closeModal"
        ></BaseButton>

        <BaseButton
          :text="$t(`global.saveChanges`)"
          size="medium"
          variant="fill"
          class="ml-2"
          @buttonClick="submit"
        >
        </BaseButton>
      </ModalSideActions>
    </template>
  </ModalSide>
</template>

<script>
import ModalSide from "@/components/common/ModalSide";
import ModalSideActions from "@/components/common/ModalSideActions";
import PackageForm from "@/components/brand-marketing/packages/PackageForm";

import CREATE_ASSET_PACKAGE from "@/graphql/CreateAssetPackage.gql";
import FIND_ASSET_PACKAGES from "@/graphql/FindAssetPackages.gql";
import FIND_ASSET_CATEGORIES_WITH_ASSET_PACKAGES from "@/graphql/FindAssetCategoriesWithAssetPackages.gql";
import FIND_FEATURED_ASSET_PACKAGES from "@/graphql/FindFeaturedAssetPackages.gql";

export default {
  components: {
    ModalSide,
    ModalSideActions,
    PackageForm,
  },

  props: {
    categories: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      name: null,
      description: "",
      categoryUuid: -1,
      selectedRoles: [],
      dueDate: null,
      isFeatured: null,
      coverImageFileUuid: null,
      sortOrder: null,
      maxLengthName: 40,
      maxLengthDescription: 184,

      formError: null,
    };
  },

  methods: {
    updateName(assetPackageName) {
      this.name = assetPackageName;
    },
    updateDescription(assetPackageDescription) {
      this.description = assetPackageDescription;
    },
    updateCategoryUuid(assetPackageCategoryUuid) {
      this.categoryUuid = assetPackageCategoryUuid;
    },
    updateSelectedRoles(assetPackageSelectedRoles) {
      this.selectedRoles = assetPackageSelectedRoles;
    },
    updateDueDate(assetPackageDueDate) {
      this.dueDate = assetPackageDueDate;
    },
    updateIsFeatured(assetPackageIsFeatured) {
      this.isFeatured = assetPackageIsFeatured;
    },
    updateCoverImageFileUuid(assetPackageCoverImageFileUuid) {
      this.coverImageFileUuid = assetPackageCoverImageFileUuid;
    },
    updateSortOrder(assetPackageSortOrder) {
      this.sortOrder = assetPackageSortOrder;
    },
    closeModal() {
      this.$emit("close");
    },
    submit() {
      const description =
        this.description.trim() !== "" ? this.description.trim() : null;

      this.$apollo
        .mutate({
          mutation: CREATE_ASSET_PACKAGE,
          variables: {
            name: this.name,
            description: description,
            categoryUuid: this.categoryUuid,
            dueDate: this.dueDate,
            isFeatured: this.isFeatured || false,
            assets: null,
            roles: this.selectedRoles,
            coverImageFileUuid: this.coverImageFileUuid,
            sortOrder: this.sortOrder,
          },
          update: (store, { data: { createdAssetPackage } }) => {
            const data = store.readQuery({
              query: FIND_ASSET_PACKAGES,
              variables: {
                categories: [],
              },
            });

            data.assetPackages.unshift(createdAssetPackage);
            store.writeQuery({ query: FIND_ASSET_PACKAGES, data });

            const assetCategories = store.readQuery({
              query: FIND_ASSET_CATEGORIES_WITH_ASSET_PACKAGES,
            });

            const updatedAssetCategories = assetCategories.categories.map(
              (assetCategory) => {
                if (assetCategory.uuid === createdAssetPackage.category.uuid) {
                  assetCategory.assetPackages.push(createdAssetPackage);
                }

                return assetCategory;
              },
            );

            store.writeQuery({
              query: FIND_ASSET_CATEGORIES_WITH_ASSET_PACKAGES,
              data: { categories: updatedAssetCategories },
            });

            if (createdAssetPackage.isFeatured) {
              const { featuredAssetPackages } = store.readQuery({
                query: FIND_FEATURED_ASSET_PACKAGES,
              });

              featuredAssetPackages.push(createdAssetPackage);

              store.writeQuery({
                query: FIND_FEATURED_ASSET_PACKAGES,
                data: {
                  featuredAssetPackages: featuredAssetPackages.sort((a, b) =>
                    a.sortOrder > b.sortOrder ? 1 : -1,
                  ),
                },
              });
            }
          },
        })
        .then(() => {
          this.$emit("resetFilters");
          this.closeModal();
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message);
        });
    },
  },
};
</script>
