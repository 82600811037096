<template>
  <div class="text-center">
    <div
      class="mb-4 inline-flex h-24 w-24 items-center justify-center rounded-full bg-success-100 text-success-600"
    >
      <IconTick class="h-16 w-16" />
    </div>
    <div class="text-sm">
      <div class="text-neutral-600">
        {{ amount }} {{ assetsText }}
        {{
          $t(
            "components.brandMarketing.packageBuilder.packageBuilderSuccess.successfullyAddedTo",
          )
        }}
      </div>
      <div class="font-medium text-neutral-900">
        <router-link
          :to="{
            name: 'brand-marketing-packages-detail',
            params: { packageUuid: uuid },
          }"
        >
          {{ name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    amount: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    uuid: {
      type: String,
      required: true,
    },
  },
  computed: {
    assetsText() {
      if (this.amount === 1) {
        return "asset";
      }
      return "assets";
    },
  },
};
</script>
