<template>
  <div
    class="border rounded mb-1 flex items-center justify-between border-neutral-50 bg-neutral-0 p-3"
  >
    <div class="flex min-w-0 items-center">
      <div class="relative mr-3 h-6 w-6 flex-shrink-0">
        <AssetsPreview
          :asset-filetype-name="asset.file.type"
          :asset-url="asset.file.url"
        />
      </div>
      <div class="truncate text-sm text-neutral-700">
        {{ asset.name }}
      </div>
    </div>
    <button
      class="focus:outline-none ml-2 flex-shrink-0 cursor-pointer text-neutral-300 transition-all duration-200 hover:text-danger-600 focus:text-danger-800"
      @click="toggleSelectAsset(asset)"
    >
      <IconDisband />
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AssetsPreview from "@/components/brand-marketing/assets/AssetsPreview";

export default {
  components: {
    AssetsPreview,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions("packageBuilder", ["toggleSelectAsset"]),
  },
};
</script>
