<template>
  <div class="hidden lg:flex">
    <BaseButton
      v-if="$can('write:assetPackages')"
      size="medium"
      variant="outline"
      :text="$t(`components.brandMarketing.toolbarActions.createPackage`)"
      class="mr-2"
      @buttonClick="openAssetModal"
    >
      <template #iconLeft>
        <IconPackageNew class="text-neutral-300" />
      </template>
    </BaseButton>

    <BaseButton
      v-if="$can('write:assets')"
      size="medium"
      variant="fill"
      :text="$t(`components.brandMarketing.toolbarActions.upload`)"
      @buttonClick="openUploadModal"
    >
      <template #iconLeft>
        <IconUpload />
      </template>
    </BaseButton>
  </div>
</template>

<script>
export default {
  methods: {
    openUploadModal() {
      this.$emit("clickedUploadButton");
    },
    openAssetModal() {
      this.$emit("clickedCreatePackageButton");
    },
  },
};
</script>
